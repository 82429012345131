
import Footer from "../../shared/components/Footer/Footer";
import NavBar from "../../shared/components/NavBar/NavBar";
import { Button, Col, Row } from "react-bootstrap";
import about_us_cover from "./assets/about-us-cover.webp";
import icon_Empowerment_through_Education from "./assets/EmpowermentthroughEducation.svg";
import icon_BridgingtheSkillsGap from "./assets/BridgingtheSkillsGap.svg";
import icon_FosteringaCommunityofTechEnthusiasts from "./assets/FosteringaCommunityofTechEnthusiasts.svg";
import icon_LifelongLearningandContinuousImprovement from "./assets/LifelongLearning andContinuousImprovement.svg";
import about_feature from "./assets/about_feature.webp";
import "./AboutUs.scss";
import { Chrono } from "react-chrono";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div className="fade-in">
      <div className="container-fluid bg-background">
        <NavBar active="about" />
      </div>

      <div className="bg-background">
        <section className="py-2">
          <div className="container p-0">
            <div className="container-fluid d-flex align-items-center justify-content-center my-4">
              <img src={about_us_cover} className="w-100" />
            </div>
            <div className="container mt-5">
              <h2
                className="font-family-class text-secondary fs-1 p-0"
                style={{ fontWeight: 500 }}
              >
                About Us{" "}
              </h2>
              <p className="text-gray about-para">
                Skill Surf, founded by alumni from the Dept. of Electronics and
                Telecommunication Engineering, University of Moratuwa, focuses
                on building an ecosystem to empower Sri Lankans to be industry
                and life-ready. The organization focuses on narrowing the gap
                between theoretical education and practical, industry-relevant
                skills, particularly in a fast-changing technological landscape.
                Skill Surf provides specialized bootcamps in technology areas
                like the Internet of Things (IoT), processor and chip design,
                and AI, meeting the increasing demands of Sri Lanka's growing
                tech industry. Its role extends to enhancing the national
                economy by equipping professionals with vital tech skills and
                helping to bridge the skills gap.
              </p>
            </div>

            <div className="container d-flex justify-content-center">
              <div className="row d-flex justify-content-center py-5 px-5">
                <div className="col-md-6 d-flex justify-content-center">
                  <div className="about-card my-2">
                    <img
                      src={icon_Empowerment_through_Education}
                      className="about-card-icon"
                    />
                    <div className="my-3">
                      <p className="about-card-title my-1">
                        Empowerment through Education
                      </p>
                      <p className="about-card-body my-1">
                        Enabling Sri Lankans to acquire essential technical
                        skills in <span style={{whiteSpace:"nowrap"}}>high-demand areas.</span> 
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 d-flex justify-content-center">
                  <div className="about-card my-2">
                    <img
                      src={icon_BridgingtheSkillsGap}
                      className="about-card-icon"
                    />
                    <div className="my-3">
                      <p className="about-card-title my-1">
                        Bridging the Skills Gap
                      </p>
                      <p className="about-card-body my-1">
                        Addressing the mismatch between traditional educational
                        offerings and the practical demands of the modern <span style={{whiteSpace:"nowrap"}}>tech
                        industry. </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 d-flex justify-content-center">
                  <div className="about-card my-2">
                    <img
                      src={icon_FosteringaCommunityofTechEnthusiasts}
                      className="about-card-icon"
                    />
                    <div className="my-3">
                      <p className="about-card-title my-1">
                        Fostering a Community of Tech Enthusiasts
                      </p>
                      <p className="about-card-body my-1">
                        Building a community of skilled professionals passionate
                        about technology <span style={{whiteSpace:"nowrap"}}>and innovation. </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 d-flex justify-content-center">
                  <div className="about-card my-2">
                    <img
                      src={icon_LifelongLearningandContinuousImprovement}
                      className="about-card-icon"
                    />
                    <div className="my-3">
                      <p className="about-card-title my-1">
                        Lifelong Learning and Continuous Improvement
                      </p>
                      <p className="about-card-body my-1">
                        Enabling continuous learning to keep pace with the
                        rapidly evolving <span style={{whiteSpace:"nowrap"}}>tech landscape.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-divider-one bg-secondary py-5"
          style={{ borderRadius: "0px 0px 0px 5rem" }}
        >
          <div className="container">
            <h2
              className="font-family-class text-white fs-3 mb-5"
              style={{ fontWeight: 500 }}
            >
              Skill Surf Portfolio
            </h2>
            <div className="row d-flex align-items-stretch ">
              <div className="col-md-3 d-flex">
                <div className="about-portfolio-card p-4 text-center mx-3 flex-grow-1 my-3 my-lg-1" style={{backgroundColor:"#F9CA9B"}}>
                  <h3>Skill Camp</h3>
                  <p>
                    Bootcamps in areas of Deep Technology, Catering to the needs
                    of the Industry
                  </p>
                </div>
              </div>

              <div className="col-md-3 d-flex">
                <div className="about-portfolio-card p-4 text-center mx-3 flex-grow-1 my-3 my-lg-1"  style={{backgroundColor:"#B5A6D6"}}>
                  <h3>Skill Cast</h3>
                  <p>
                    Workshops, TECH talks, Series of video tutorials in unique
                    areas of technology and Podcasts
                  </p>
                </div>
              </div>

              <div className="col-md-3 d-flex">
                <div className="about-portfolio-card p-4 text-center mx-3 flex-grow-1 my-3 my-lg-1"  style={{backgroundColor:"#B5D7A8"}}>
                  <h3>Skill Hive</h3>
                  <p>
                    A platform to foster relationships between industry and
                    university students
                  </p>
                </div>
              </div>

              <div className="col-md-3 d-flex">
                <div className="about-portfolio-card p-4 text-center mx-3 flex-grow-1 my-3 my-lg-1"  style={{backgroundColor:"#EA9999"}}>
                  <h3>Skill Reach</h3>
                  <p>
                    Community outreach and knowledge distribution across
                    different areas of the country
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <a
                href="https://docs.google.com/presentation/d/1mPg_zTWfkgGSF_1b5LW20q0u2JSskBtTqdlMHWQwXpg/edit?usp=sharing"
                target="_blank"
              >
                <Button
                  className="text-white register-button m-2 px-3 py-2 mt-3"
                  style={{ fontWeight: 300, width: "auto" }}
                >
                  View Skill Surf Portfolio
                </Button>
              </a>
            </div>
          </div>
        </section>
      </div>

      <section className="section-two my-5 ">
        <div className="container ">
          <Row className="align-items-center">
            <Col className="col-12 col-lg-5  d-flex  justify-content-lg-end justify-content-md-center justify-content-center align-items-center pt-2">
              <img
                src={about_feature}
                alt="Vector representing Our Talent Enhancement Philosophy"
                className="img-fluid"
                style={{ maxWidth: "100%" }}
              />
            </Col>
            <Col className="col-12 col-lg-7 ps-lg-4 ps-3 ps-lg-1">
              <h2
                className="text-secondary font-family-class fs-3 mt-5"
                style={{ fontWeight: 500 }}
              >
                Interested in partnering with us or getting <br />
                our services?
              </h2>
              <p
                className="font-family-class text-gray fs-5"
                style={{ fontWeight: 400 }}
              >
                Let’s catch up and explore how we can work together!
              </p>
              <Link to={"/contact-us"}>
                <Button
                  className="text-white register-button px-3 py-2 "
                  style={{ fontWeight: 300, width: "auto" }}
                >
                  Reach Out to Us
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </section>

      <section className="py-3 mb-5">
        <h2
          className="font-family-class text-secondary text-center fs-3 my-5"
          style={{ fontWeight: 500 }}
        >
          Our Timeline
        </h2>

        <div className="timeline-container pt-2">
          <Chrono
            mode="VERTICAL_ALTERNATING"
            hideControls
            toolbarPosition="BOTTOM"
            disableToolbar
            cardHeight={150}
            
          >

<div className="time-card">
              <h3>January 2025</h3>
              <p>
              Second iteration of{" "}
                <span className="hl">
                  “Embedded Product Design for IoT”
                </span>{" "}
                released
              </p>

              <p className="mt-1">
              Second iteration of{" "}
                <span className="hl">
                  “System Verilog for ASIC/FPGA Design & Simulation”
                </span>{" "}
                released in-collaboration with Synopsys
              </p>
            </div>


            <div className="time-card">
              <h3>September 2024
              </h3>
              <p>
              First iteration of{" "}
                <span className="hl">
                “Fundamentals of Digital System Design”
                </span>{" "}
                conducted
              </p>
            </div>


            <div className="time-card">
              <h3>July 2024</h3>
              <p>
                Second iteration on{" "}
                <span className="hl">
                  “Embedded Machine Learning for Edge Computing”
                </span>{" "}
                conducted
              </p>
            </div>

            <div className="time-card">
              <h3>June 2024</h3>
              <p>
                Second free public workshop on{" "}
                <span className="hl">“Introduction to Generative AI”</span>
              </p>
            </div>

          

            <div className="time-card">
              <h3>January 2024</h3>
              <p>
                Third iteration of{" "}
                <span className="hl">
                  “Microcontroller-Based Embedded System Design”
                </span>{" "}
                conducted
              </p>
            </div>

            <div className="time-card">
              <h3>October 2023</h3>
              <p>
                First iteration of{" "}
                <span className="hl">
                  “Cryptography Fundamentals: Securing the Digital World”
                </span>{" "}
                released
              </p>
            </div>

            <div className="time-card">
              <h3>May 2023</h3>
              <p>
                First iteration of{" "}
                <span className="hl">“Embedded Product Design for IoT”</span>{" "}
                released
              </p>
            </div>

            <div className="time-card">
              <h3>April 2023</h3>
              <p>
                Plans to setup <span className="hl">“Skill Hive”</span> with
                potential collaborators were discussed
              </p>
            </div>

            <div className="time-card">
              <h3>February 2023</h3>
              <p>
                First iteration of{" "}
                <span className="hl">
                  “System Verilog for ASIC/FPGA Design & Simulation”
                </span>{" "}
                released in-collaboration with Synopsys
              </p>
            </div>

            <div className="time-card">
              <h3>January 2023</h3>
              <p>
                First free public workshop on{" "}
                <span className="hl">“Introduction to Quantum Computing”</span>
              </p>
            </div>

            <div className="time-card">
              <h3>December 2022</h3>
              <p>
                Idea to organize free workshops and content through{" "}
                <span className="hl">“Skill Cast”</span> for the community
              </p>
            </div>

            <div className="time-card">
              <h3>November 2022</h3>
              <p>
                Second iteration of{" "}
                <span className="hl">
                  “Microcontroller-Based Embedded System Design”
                </span>{" "}
                conducted
              </p>
            </div>

            <div className="time-card">
              <h3>September 2022</h3>
              <p>
                First iteration of{" "}
                <span className="hl">
                  “Embedded Machine Learning for Edge Computing”
                </span>{" "}
                released
              </p>
            </div>

            <div className="time-card">
              <h3>July 2022</h3>
              <p>
                <a href="https://www.skillsurf.lk/">Skill Surf</a> was officially formed to provide 8-week certificate
                bootcamps to Sri Lankan Public through{" "}
                <span className="hl">“Skill Camp”</span>
              </p>
            </div>

            <div className="time-card">
              <h3>August 2021</h3>
              <p>
                <a href="https://www.facebook.com/AmCenterSL/photos/a.2149978915120022/4135154256602468/?type=3" target="_blank">Kick-Starter on Embedded System Design Workshop</a>  with ENTC E-Club
                with funding support from the American Center in Colombo{" "}
                <span className="hl">[12-weeks]</span>
              </p>
            </div>

            <div className="time-card">
              <h3>July 2021</h3>
              <p>
              <a href="https://uom.lk/courses/training-course-microcontroller-based-system-design" target="_blank"> Training Course on{" "}
                <span className="hl">
                “Microcontroller-Based Embedded System Design” 
                </span></a>{" "}
                from ENTC <span className="hl">[8-weeks]</span>
              </p>
            </div>

            <div className="time-card">
              <h3>May 2021</h3>
              <p>
                IEEE SLInspire <a href="https://slinspire.lk/events/embedded-workshop/" target="_blank">Embedded Systems Workshop for Beginners</a> {" "}
                <span className="hl">[4-weeks]</span>
              </p>
            </div>

            <div className="time-card">
              <h3>March 2021</h3>
              <p>
                Initial idea to create a free workshop for embedded systems and
                microcontrollers
              </p>
            </div>
          </Chrono>
        </div>
      </section>
      <Footer isFinancialInquiries={true} />
    </div>
  );
}
