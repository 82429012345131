import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Home.scss";
import PrimaryButton from "../../shared/components/Primary-Button/PrimaryButton";
import rocketIllustration from "./assets/rocket-illustration.webp";
import circuitIllustration from "./assets/circuit-illustraion.webp";
import SecondaryLink from "../../shared/components/Secondary-Link/SecondaryLink";
import legoIllustration from "./assets/lego-illustration.webp";
//import rightArrow from "./assets/right-arrow.webp";
import chipIllustrationOne from "./assets/chip-illustration.webp";
import fdsd_image from "./assets/fdsd.webp";
import chipIllustrationTwo from "./assets/chip-illustration-2.webp";
import shieldIllustration from "./assets/shield-illustration.webp";
import industryOne from "./assets/industry-1.webp";
import industryTwo from "./assets/industry-2.webp";
import industryThree from "./assets/industry-3.webp";
import industryFour from "./assets/industry-4.webp";
import successIcon from "./assets/success_icon.svg";
import errorIcon from "./assets/error_icon.svg";
import unicLogo from "./assets/unic-logo.webp";
//import entcLogo from "./assets/entc-logo.webp";
import synopsysLogo from "./assets/synopsys-logo.webp";
import NavBar from "../../shared/components/NavBar/NavBar";
import Footer from "../../shared/components/Footer/Footer";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import timezz from "timezz";
import Carousel from "react-bootstrap/Carousel";

import iot_course from "./assets/iot_course.webp";
import UBC_Quantum_logo from "../CoursePage/assets/quantum_lab.webp";
import ieee_logo from "../CoursePage/assets/ieee.webp";
//import cryptography_course from "./assets/crypto_course.webp";
import embedded_ml_image from "./assets/embedded_ml.webp";

import Modal from "react-bootstrap/Modal";
import Spinner from "../../shared/components/Spinner/Spinner";
//import cryptographyPaymentDetailsPDF from "../../pdfs/Payment Details - Cryptography Fundamentals_ Securing the Digital World.pdf";

const Home: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emailInvalidText, setEmailInvalidText] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
      /*   timezz(document.querySelector(".timer")!, {
      date: new Date("2025-01-24 00:00"),
    });  
    
   timezz(document.querySelector(".timer2")!, {
      date: new Date("2025-01-24 00:00"),
    });  */
  });
  const validate = () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email || !regex.test(email)) {
      if (email) {
        setEmailInvalidText("Your email is invalid");
      } else {
        setEmailInvalidText("Your email is required");
      }
      setSubscriptionSuccessful(false);
      setEmailExist(false);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e: any) => {
    setShowSpinner(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    if (validate()) {
      setInvalidEmail(false);
      const url =
        "https://us-central1-skill-surf.cloudfunctions.net/api/subscribe";
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setShowSpinner(false);
          setEmail("");
          if (data.message === "Subscribed successfully") {
            setSubscriptionSuccessful(true);
            setShow(true);
            setEmailExist(false);
            setShowSpinner(false);
          } else if (data.message === "Email already exist") {
            setEmailExist(true);
            setSubscriptionSuccessful(false);
            setShow(true);
            setShowSpinner(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setSubscriptionSuccessful(false);
          setShow(true);
          setEmailExist(false);
          setShowSpinner(false);
        });
    } else {
      setInvalidEmail(true);
      setShow(true);
      setShowSpinner(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <div className="home-container fade-in">
      <NavBar background="bg-background" />
      <section
        className="bg-background section-one d-flex 
        justify-content-center align-items-center"
        style={{ paddingBottom: "4rem" }}
      >
        <div className="container py-3 py-sm-5 py-xl-0">
          <Carousel controls={false} className="py-5" interval={3000}>
           
            <Carousel.Item>
              <Row className="align-items-center">
                <Col className="col-12 col-lg-6">
                  <h1
                    className="display-4 text-primary text-center text-lg-start font-family-class"
                    style={{ fontWeight: 700 }}
                  >
                    Skill Surf
                  </h1>
                  <p
                    className="text-secondary text-center text-lg-start fs-5 font-family-class"
                    style={{ fontWeight: 400 }}
                  >
                    Empowering Sri Lankans with technical and life skills to
                    make them industry and life&#8209;ready.
                  </p>
                </Col>
                <Col className="col-12 col-lg-6 d-flex align-item-center justify-content-lg-end justify-content-md-center justify-content-center">
                  <img
                    src={rocketIllustration}
                    alt="An illustration of rocket flying"
                    className="img-fluid"
                  />
                </Col>
              </Row>
</Carousel.Item> 
        
          </Carousel>
        </div>
      </section>
      {/* <section className="bg-background section-one d-flex justify-content-center align-items-center ">
        <div className="container py-3 py-sm-5 py-xl-0">
          <Row className="align-items-center">
            <Col className="col-12 col-lg-6">
              <h1
                className="display-4 text-primary text-center text-lg-start font-family-class"
                style={{ fontWeight: 700 }}
              >
                Skill Surf
              </h1>
              <p
                className="text-secondary text-center text-lg-start fs-5 font-family-class"
                style={{ fontWeight: 400 }}
              >
                Empowering Sri Lankans with technical and life skills to make
                them industry and life&#8209;ready.
              </p>
              <div className="bg-secondary-background p-4 rounded-3">
                <Row className="align-items-center">
                  <Col className="col-12 col-xl-6 d-xl-block d-flex justify-content-center">
                    <Link to={"/program-catalog"}>Browse Programs</Link>
                  </Col>
                  <Col className="col-12 col-xl-6">
                    <p
                      className="fs-6 text-primary font-family-class text-xl-start text-center mt-xl-0 mt-3"
                      style={{ fontWeight: 500 }}
                    >
                      Course Launching Soon
                    </p>
                    <p
                      className="fs-6 text-secondary font-family-class mb-0 mt-0 text-xl-start text-center"
                      style={{ fontWeight: 700 }}
                    >
                      Digital Circuit Design & Verification With SystemVerilog{" "}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-lg-6">
              <img
                src={rocketIllustration}
                alt="An illustration of rocket flying"
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
      </section> */}

      <section
        className="section-divider-one bg-secondary p-5"
        style={{ borderRadius: "0px 0px 0px 5rem", marginBottom: "4%" }}
      >
        <div className="container">
          <Row className="align-items-center pt-4 pb-4 pt-md-5 pb-md-5 d-flex justify-content-between">
            <Col className="col-12 col-md-6 col-lg-9">
              <p
                className="text-white font-family-class fs-4 mb-1 text-center text-md-start"
                style={{ fontWeight: 500 }}
              >
                Accelerate your career with job&#8209;ready skills
              </p>
              <p
                className="text-white font-family-class fs-6 mb-0 text-center text-md-start"
                style={{ fontWeight: 400 }}
              >
                Get a comprehensive training within 8 weeks, including a
                completion <br />
                certification from Skill Surf!
              </p>
            </Col>
            <Col className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 d-flex d-md-block justify-content-center">
              <SecondaryLink to={"/program-catalog"} name="Explore Programs" />
            </Col>
          </Row>
        </div>
      </section>

      <section className="section-two my-5 ">
        <div className="container ">
          <Row className="align-items-center p-2 pt-5 pb-5">
            <Col
              className="col-12 col-lg-7 pt-4 "
              xs={{ order: 2 }}
              md={{ order: 2 }}
              lg={{ order: 1 }}
            >
              <h2
                className="text-secondary font-family-class fs-3 mt-5"
                style={{ fontWeight: 500 }}
              >
                Our Talent Enhancement Philosophy
              </h2>
              <p
                className="font-family-class text-gray fs-6"
                style={{ fontWeight: 400 }}
              >
                Our dedicated team creates content and plans execution through
                months of planning for every course to deliver an
                outcome-oriented course that combines strong fundamentals and
                hands-on experience.
              </p>
              <Row className="mt-1">
                <Col className="col-12 col-lg-4">
                  <div className="talent-sub-topic d-lg-flex align-items-center">
                    <h3 className="font-family-class text-primary fs-5">
                      Outcome Oriented
                    </h3>
                  </div>
                  <p
                    className="font-family-class text-light-gray fs-6 mb-lg-0"
                    style={{ fontWeight: 400, textAlign: "justify" }}
                  >
                    There are two ways to learn a subject. Learn the concepts,
                    and then the student figures out what to do/how to apply the
                    concepts. Or, start with the goal of creating something and
                    learn the concepts to achieve that goal. We prefer the
                    latter.
                  </p>
                </Col>
                <Col className="col-12 col-lg-4">
                  <div className="talent-sub-topic d-lg-flex align-items-center">
                    <h3 className="font-family-class text-primary fs-5">
                      Hands-On
                    </h3>
                  </div>
                  <p
                    className="font-family-class text-light-gray fs-6 mb-lg-0"
                    style={{ fontWeight: 400, textAlign: "justify" }}
                  >
                    Throughout the course, our instructors will work with you in
                    real time, help you when you are stuck and improve
                    iteratively. Because mastering something is always an
                    iterative, hands-on process, and each student has their own
                    approach.
                  </p>
                </Col>
                <Col className="col-12 col-lg-4">
                  <div className="talent-sub-topic d-lg-flex align-items-center">
                    <h3 className="font-family-class text-primary fs-5">
                      Strong Fundamentals
                    </h3>
                  </div>
                  <p
                    className="font-family-class text-light-gray fs-6 mb-lg-0"
                    style={{ fontWeight: 400, textAlign: "justify" }}
                  >
                    While abstraction is essential, what differentiates a highly
                    skilled engineer is an in-depth understanding of the
                    internals. Having a strong grasp of fundamentals is critical
                    in achieving this goal.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              className="col-12 col-lg-4 offset-lg-1 d-flex  justify-content-lg-end justify-content-md-center justify-content-center align-items-center pt-2"
              xs={{ order: 1 }}
              md={{ order: 1 }}
              lg={{ order: 2 }}
            >
              <img
                src={legoIllustration}
                alt="Vector representing Our Talent Enhancement Philosophy"
                className="img-fluid"
                style={{ maxWidth: "80%" }}
              />
            </Col>
          </Row>
        </div>
      </section>

      <section
        className="bg-background section-three "
        style={{
          borderRadius: "0px 0px 0px 5rem",
          marginTop: "6%",
          marginBottom: "3%",
        }}
      >
        <div className="section-three-new container py-5 px-4 px-lg-0">
          <Row className="align-items-center align-self-center focus-area">
            <Col className="col-12 col-md-6 p-lg-4 ">
              <h2
                className="font-family-class text-primary fs-3"
                style={{ fontWeight: 500 }}
              >
                Current Focus Areas
              </h2>
              <p
                className="font-family-class text-gray fs-6"
                style={{ fontWeight: 400 }}
              >
                We focus on future-proof technologies that are at the forefront
                of Industry 4.0. We will constantly be expanding our scope as we
                develop more content. But, we are not in a hurry to expand at
                the expense of quality. Good things take time!
              </p>
            </Col>

            <Col className="col-12 col-md-5 offset-md-1 mt-4 mt-lg-0 align-items-end">
              <div className="focus-sub-topics d-flex justify-content-between align-items-center p-3 rounded-3 my-4 ">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={chipIllustrationOne}
                    alt="An illustration of computer chip"
                  />
                  <p
                    className="mb-0 font-family-class text-white"
                    style={{ fontWeight: 500 }}
                  >
                    Internet of Things
                  </p>
                </div>
                {/*  <img
                  src={rightArrow}
                  alt="An illustration of right angle arrow"
                  width={"11px"}
                  height={"19px"}
                  style={{ display: "none" }}
                /> */}
              </div>
              <div className="focus-sub-topics d-flex justify-content-between align-items-center p-3 rounded-3 my-4">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={chipIllustrationTwo}
                    alt="An illustration of computer chip"
                  />
                  <p
                    className="mb-0 font-family-class text-white"
                    style={{ fontWeight: 500 }}
                  >
                    Computer Architecture
                  </p>
                </div>
                {/*  <img
                  src={rightArrow}
                  alt="An illustration of right angle arrow"
                  width={"11px"}
                  height={"19px"}
                  style={{ display: "none" }}
                /> */}
              </div>
              <div className="focus-sub-topics d-flex justify-content-between align-items-center p-3 rounded-3 my-4">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src={shieldIllustration}
                    alt="An illustration of shield"
                  />
                  <p
                    className="mb-0 font-family-class text-white"
                    style={{ fontWeight: 500 }}
                  >
                    Cybersecurity
                  </p>
                </div>
                {/*  <img
                  src={rightArrow}
                  alt="An illustration of right angle arrow"
                  width={"11px"}
                  height={"19px"}
                  style={{ display: "none" }}
                /> */}
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="section-four py-5 my-5 ">
        <div className="section-four-contents container w-100 px-sm-3">
          <h2
            className="font-family-class text-secondary text-center fs-3 mt-lg-5"
            style={{ fontWeight: 500 }}
          >
            Become Industry and Life&#8209;Ready
          </h2>
          <Row className="mt-5 w-100 mx-1">
            <Col className="col-12 col-md-6 p-lg-0">
              <div className="d-flex gap-3 align-items-center">
                <img
                  src={industryOne}
                  alt="Icon representing Industry Leaders"
                  width={"81px"}
                />
                <p
                  className="mb-0 font-family-class text-gray fs-6"
                  style={{ fontWeight: 400 }}
                >
                  State&#8209;of&#8209;the&#8209;art content co&#8209;created
                  with industry leaders
                </p>
              </div>
            </Col>

            <Col className="col-12 col-md-6 mt-4 mt-md-0 p-lg-0 ">
              <div className="d-flex gap-3 align-items-center justify-content-start">
                <img
                  src={industryTwo}
                  alt="Icon representing Strong Fundamentals"
                  width={"81px"}
                />
                <p
                  className="mb-0 font-family-class text-gray fs-6"
                  style={{ fontWeight: 400 }}
                >
                  Hands&#8209;on learning coupled with strong fundamentals
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 w-100 mx-1 ">
            <Col className="col-12 col-md-6 p-lg-0">
              <div className="d-flex gap-3 align-items-center">
                <img
                  src={industryThree}
                  alt="Icon representing Personalized Feedback"
                  width={"81px"}
                />
                <p
                  className="mb-0 font-family-class text-gray fs-6"
                  style={{ fontWeight: 400 }}
                >
                  Personalized feedback to ensure no&#8209;one is left behind
                </p>
              </div>
            </Col>
            <Col className="col-12 col-md-6 mt-4 mt-md-0 p-lg-0">
              <div className="d-flex gap-3 align-items-center justify-content-start">
                <img
                  src={industryFour}
                  alt="Icon representing Skilled Teacher"
                  width={"81px"}
                />
                <p
                  className="mb-0 font-family-class text-gray fs-6"
                  style={{ fontWeight: 400 }}
                >
                  Taught by skilled teachers with a strong technical background
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="section-five pb-5 mb-5">
        <div className="container d-flex justify-content-center align-items-center w-100">
          <div className="section-five-contents my-3 w-100 container ">
            <h2
              className="font-family-class text-secondary text-center fs-3 mt-lg-5 mb-5"
              style={{ fontWeight: 500 }}
            >
              Friends & Allies
            </h2>
            <div className="d-flex justify-content-evenly  align-items-center flex-wrap mt-5 w-100 gap-4">
              <a href="https://www.synopsys.com/">
                <img
                  src={synopsysLogo}
                  alt="Synopsys Logo"
                  className=" mt-2"
                  height={50}
                />
              </a>

              <a href="https://www.ubcquantum.com/">
                <img
                  src={UBC_Quantum_logo}
                  alt="ubc Logo"
                  className=" mt-2"
                  height={55}
                />
              </a>
              <a href="https://ieee.lk/">
                {" "}
                <img
                  src={ieee_logo}
                  alt="ieee Logo"
                  className=" mt-2"
                  height={50}
                />
              </a>

              <a href="http://www.uniconsultancy.com/">
                {" "}
                <img
                  src={unicLogo}
                  alt="Unic Logo"
                  className=" mt-2"
                  height={50}
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-secondary section-divider-two p-5 my-lg-5"
        style={{ borderRadius: "5rem 0px 0px 0px" }}
      >
        <div className="container d-flex justify-content-center py-lg-5">
          <div className="section-divider-two-contents">
            <div>
              <p
                className="font-family-class text-white fs-4 text-center mt-4"
                style={{ fontWeight: 500 }}
              >
                Get email updates about upcoming courses
              </p>
            </div>
            <form noValidate onSubmit={handleSubmit}>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3">
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    className="subscribe-email rounded-3 font-family-class fs-6"
                    style={{ fontWeight: 400 }}
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <PrimaryButton
                    onClick={() => {}}
                    name="Subscribe"
                    classes="subscribe-button"
                    type="submit"
                  />
                </div>
              </div>
            </form>

            <Modal show={show} onHide={handleClose} centered>
              <Modal.Body className="d-flex flex-column">
                {subscriptionSuccessful || emailExist || invalidEmail ? (
                  <div className="bg-white d-flex gap-3 px-3 rounded-pill mb-3 justify-content-center">
                    {subscriptionSuccessful && (
                      <img src={successIcon} alt="Success icon" />
                    )}
                    {emailExist && <img src={errorIcon} alt="Error icon" />}
                    {invalidEmail && <img src={errorIcon} alt="Error icon" />}
                    <p className="font-family-class text-gray mb-0">
                      {subscriptionSuccessful
                        ? "Thank You! Your email address has been added to the mailing list."
                        : emailExist
                        ? "Your email already exist in our mailing list!"
                        : emailInvalidText}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Okay
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </section>
      <Spinner showSpinner={showSpinner} />
      <Footer isFinancialInquiries={true} />
    </div>
  );
};

export default Home;
