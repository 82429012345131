import systemVerilogForAsicFpgaDesignAndSimulationImage from "../../components/ProgramCatalog/assets/system-verilog-for-asic-fpga-design-and-simulation.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import abarajithanGnaneswaran from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import kaveeshaYalegama from "../../components/CoursePage/assets/instructor-img/kaveesha-yalegama.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin.webp";
import hasiniAthukorale from "../../components/CoursePage/assets/instructor-img/Hasini_Athukorale.webp";
import supunk from "../../components/CoursePage/assets/instructor-img/supun.jpg";
import pasinduSandima from "../../components/CoursePage/assets/instructor-img/Pasindu-Sandima.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import svdsFlyer from "../../components/CoursePage/assets/SVDS-main-flyer-min.webp";
import jazoolee from "../../components/CoursePage/assets/instructor-img/Jazoolee.jpg";
import achintha from "../../components/CoursePage/assets/instructor-img/achintha.png";
import { ECourseStatus, ICourse } from "../utils/utils";
import synopsysLogo from "../../components/CoursePage/assets/synopsys-logo.webp";

export const systemVerilogForAsicFpgaDesignAndSimulation: ICourse = {
  header: {
    courseImage: systemVerilogForAsicFpgaDesignAndSimulationImage,
    programCategory: "Short Course",
    difficultyLevel: "Beginner",
    courseTitle: "{System}Verilog for ASIC/FPGA Design & Simulation (Batch 02)",
    specialization: "Build Your Own CPU",
    courseDescription:
      "The importance of processor design becomes evident when we consider the rapid pace of technological advancement in the field of computing. Get comprehensive training on {System}Verilog for ASIC/FPGA Design & Simulation throughout 8 weeks.",
    registerButton: "Registration Closed!",
    registerLink:
      "",
    secondaryName: "Stay tuned for updates",
  },
  headerSecondSection: {
    starCount: 5,
    reviewCount: 30,
    registrationDeadline: "",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "New Course",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
      {
        collaborationLogo: synopsysLogo,
        collaborationLogosName: "Synopsys Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading: "Introduction  to HDLs, FPGAs, Synthesis, Verification",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Learn SystemVerilog design and simulation through seven digital circuits:",
          description: "",
          subContent: [
            {
              heading: "",
              description: "1-bit full adder",
            },
            {
              heading: "",
              description: "N-bit full adder",
            },
            {
              heading: "",
              description: "Combinational ALU",
            },
            {
              heading: "",
              description: "Counter",
            },
            {
              heading: "",
              description: "Parallel to Serial Converter (State machine)",
            },
            {
              heading: "",
              description: "FIR Filter",
            },
            {
              heading: "",
              description: "Matrix-Vector Multiplier",
            },
          ],
        },
        {
          heading: "Best practices, gotchas & more",
          description: "",
          subContent: [],
        },
        {
          heading: "OSIC Tools, OpenLane, and TinyTapeout",
          description: "",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
    {
      question:
        "I have no prior knowledge of SystemVerilog or processor design. Will I be able to do this course?",
      answer:
        "The course does not require any prior experience in processor design. As long as you have a background in basic computer programming, we will provide you with the necessary ingredients to get started in this domain and become an expert.",
    },
    {
      question:
        "Do I need electronic equipment/software licenses to complete this course?",
      answer:
        "No hardware is required for the course. The required software licenses will be provided to you since we have partnered with Synopsys for the course. You will get access to Synopsys tools.",
    },
    {
      question:
        "Will I be able to build things at the end of the course, or is it only theory?",
      answer:
        "Of course, you will be able to build things. The course is outcome-oriented, so at the end of the course, you will know how to develop cool processor components from scratch.",
    },
    {
      question: "Is the course taught online?",
      answer:
        "Yes. The course lectures are all conducted online. We will arrange hands-on sessions at ENTC laboratories as required.",
    },
    {
      question: "Will lecture recordings be made available?",
      answer:
        "Yes. All course materials will be made available through an online learning management system called Moodle. All paid participants will be granted access to the course Moodle page.",
    },
  ],
  experts: [
    {
      image: abarajithanGnaneswaran,
      name: "Mr. Abarajithan Gnaneswaran",
      designation: "PhD Candidate, University of California San Diego, USA",
    },
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc, Electrical and Computer Eng., UBC, Canada",
    },
    {
      image: supunk,
      name: "Mr. Supun Kuruppu",
      designation:
        "Associate Electronics Engineer, Paraqum Technologies, Sri Lanka",
    },
    {
      image: jazoolee,
      name: "Mr. Jazoolee Ahamed",
      designation: "3rd Year Undergraduate, ENTC, UoM",
    },
    {
      image: achintha,
      name: "Dr. Achintha Iroshan",
      designation: "Staff Program Manager, Synopsys",
    },
    /* {
      image: kaveeshaYalegama,
      name: "Mr. Kaveesha Yalegama",
      designation: "Final year undergraduate, ENTC, UoM",
    }, */

    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [
    {
      fact: "SystemVerilog is not an entirely new RTL language. With its rich set of extensions to the existing Verilog HDL, SystemVerilog is backward compatible with both Verilog 95 and Verilog 2001. Many of these extensions to Verilog make it easier to create accurate, synthesizable models of designs of any size",
      factBold: "",
      author: "-EETimes-",
    },
    {
      fact: "The global Application Processor Market is expected to reach USD 38 billion by 2024, at a CAGR of 5.5% from 2018 to 2024.",
      factBold: "",
      author: "-MarketWatch-",
    },
  ],
  classSchedule: [
    "11 January 2025 (Concluded)",
    "25 January 2025",
    "26 January 2025",
    "01 February 2025",
    "02 February 2025",
    "08 February 2025",
    "09 February 2025",
    "15 February 2025",
    "16 February 2025",
    "22 February 2025",
    "23 February 2025",
    "09 March 2025",
    "22 March 2025",
    "23 March 2025",
    "30 March 2025",
  ],
  showSchedule: true,
  prerequisite: "Basic knowledge of programming in any language.",
  isCourseFlyer: true,
  flyer: {
    description:
      "Learn more about System}Verilog for ASIC/FPGA Design & Simulation short course from the Course Outline Session flyer",
    button: "View Course Flyer",
    image: svdsFlyer,
  },
  courseStatus: ECourseStatus.NEW,
  youtubeVideoUrl: "https://www.youtube-nocookie.com/embed/ClScIlHIBbk",
  showTimer: false,
  learnMore: {
    description:
      "Learn more about SystemVerilog design & simulation and the custom processor design market in general",
    button: "Read More",
    articleLink:
      "https://medium.com/@SkillSurfLK/embedded-machine-learning-for-edge-computing-4598eb8df1b1",
  },

  /* firstSessionFreeDetails: {
    instruction: {
      isInstructive: false,
      instructiveText:
        "The first session, which was free of charge to everyone, was held on 11 January 2025. The recording of the session is made available below.",
      instructiveTextSecond:
        "Only paid participants can attend from day 2 onwards. So, please complete the payment by",
      date: "",
      dateSecond: "23 January 2025.",
      buttonText: "View Course Outline",
      buttonTextInfo: "View Slides",
      viewIntruction: "",
      instructionVideoTopic:
        "Recording of the first sessions held on 11 January 2025",
      instructionVideoUrl: "oy3kuFWbtgg",
      videoTwoTopic: "",
      videoTwoUrl: "",
      viewIntructionLink: "",
      buttonLink: "",
      buttonLinkInfo:
        "",
      moreInformation:
        "For any questions, please send a WhatsApp message to Ms. Shehani Jayasinghe at +94 767 666 555.",
      slideButtonText: "Download Slides:",
      paymentInstructionButtonText: "here",
      paymentInstructionButtonLink:
        "https://drive.google.com/file/d/1SKkpGmoYZwXH7SDMyDu1VPr6PPgerjLa/view?usp=sharing",
      paymentInstructionText:
        "Payment and other related instructions can be found",
      enrollmentFormLink: "https://forms.gle/XjZm2F35TYajh6We9",
      
    },
  }, */
  meetSection: {
    meetDetails: false,
    introPara:
      "The introductory session, which is free of charge to everyone, will be held on 11th January (Saturday) 2025 at 7.00 pm IST. This first session will give an overview of the course, the content covered, and the expected outcomes. Please use the Zoom link to join the session:",
    zoomLink:
      "https://learn.zoom.us/j/66480277252?pwd=STVxa2hlRi9SS0hGOU1RZlY0OTRwUT09",
    meetId: "664 8027 7252",
    passCode: "R?4xedWg",
    zoomLinkText: "Click here to join ",
    introSecondPara:
      "The introductory session and an email following the session will provide further instructions on how to make the payments and enroll in the course. See you on 11th!",
  },
};
